@font-face {
  font-family: "Basis Grotesque Pro";
  src: url("./BasisGrotesque/basis-grotesque-light-pro.woff2") format("woff2");
  font-weight: 300;
}

@font-face {
  font-family: "Basis Grotesque Pro";
  src: url("./BasisGrotesque/basis-grotesque-regular-pro.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Basis Grotesque Pro";
  src: url("./BasisGrotesque/basis-grotesque-medium-pro.woff2") format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: "Basis Grotesque Pro";
  src: url("./BasisGrotesque/basis-grotesque-bold-pro.woff2") format("woff2");
  font-weight: 600;
}

@font-face {
  font-family: "Basis Grotesque Pro";
  src: url("./BasisGrotesque/basis-grotesque-black-pro.woff2") format("woff2");
  font-weight: 700;
}

@font-face {
  font-family: "Basis Grotesque Pro Mono";
  src: url("./BasisGrotesque/basis-grotesque-mono-light-pro.woff2")
    format("woff2");
  font-weight: 300;
}

@font-face {
  font-family: "Basis Grotesque Pro Mono";
  src: url("./BasisGrotesque/basis-grotesque-mono-regular-pro.woff2")
    format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Basis Grotesque Pro Mono";
  src: url("./BasisGrotesque/basis-grotesque-mono-medium-pro.woff2")
    format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: "Basis Grotesque Pro Mono";
  src: url("./BasisGrotesque/basis-grotesque-mono-bold-pro.woff2")
    format("woff2");
  font-weight: 600;
}

/* ./src/index.css */
@tailwind base;
@tailwind components;

@tailwind utilities;

html,
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* box-sizing: border-box;
  width: 100vw;
  overflow-x: hidden; */
  scrollbar-width: none;
  background-color: #fff;
}

:root {
  --tray-time: 0ms;
}

#myVideo {
  z-index: -100;
  /* position: fixed;
  top: 0; */
  height: auto;
  object-fit: contain;
  width: 100%;
}

::-webkit-scrollbar {
  display: "none";
}

.noExpansion {
  width: 100vw;
  overflow-x: hidden;
}

/* .portfolio {
  scale: 2;
} */

@media screen and (max-width: 500px) {
  .GALLERY {
    width: 100% !important;
  }
}

.blurBG {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.4);
  /* opacity: 10%; */
}

.fellowImg {
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.fellowUnHovered {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

/* "primary": "#7000FF",
"buttonText": "#8a32ff",
"buttonBG": "#200647",
"primaryDark": "#160232", */

/* .animateGradient {
  background: linear-gradient(
    45deg,
    #200647,
    #160232,
    #7000ff,
    #8a32ff,
    #bb70ff,
    #c37bff,
    #8a32ff,
    #7000ff,
    #160232,
    #200647
  );
  background-size: 700% 100%;
  animation: gradient 16s linear infinite;
  animation-direction: alternate;
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
} */

.noScroll::-webkit-scrollbar {
  display: none;
}

.modal_bg {
  position: absolute;
  width: 100vw;
  height: 100vh;
  transition: all 0.3s ease-in;
  /* background-color: rgba(17, 17, 17, 0.5); */
  z-index: -1;
  /* display: none; */
}

@media screen and (max-width: 450px) {
  .modal_bg {
    /* background-color: rgba(17, 17, 17, 1) !important; */
  }
}

.modal_bg_toggled {
  /* display: block; */
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  overflow-y: auto;
  /* background-color: rgba(17, 17, 17, 0.9); */
}

.containerModal {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 0rem;
  /* padding-bottom: 3rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modalCard {
  max-width: 1000px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.stonks {
  /* background-image: */
  background-image: url("./stonks.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.tray-menu-open {
  transform: translateX(0);
  transition: transform var(--tray-time);
}

.tray-menu-closed {
  transform: translateX(-110vw);
  transition: transform var(--tray-time);
}

.tray-menu-width {
  width: calc(100vw - 4rem - 1rem - 1rem);
}

.mobile-search-button-width {
  width: calc(100vw - 4rem - 1rem - 1rem - 1rem - 1rem);
}

.tray-overlay-open {
  /* opacity: ; */
  transition: opacity var(--tray-time);
}

.tray-overlay-closed {
  opacity: 0;
  transition: opacity var(--tray-time);
}

.menu-open {
  left: 0;
  visibility: visible;
  display: block;
}

.menu-closed {
  left: -100vw;
  visibility: hidden;
  display: none;
}
